import { DefaultResponse } from '@Api-generated';

export default class DefaultResponseModel implements DefaultResponse {
  public code: number;
  public message: string;
  public data: Array<string>;

  constructor(code: number, message: string, data: Array<string>) {
    this.code = code;
    this.message = message;
    this.data = data;
  }

  public static hydrateFromApi(defaultResponse: DefaultResponse): DefaultResponseModel {
    return new DefaultResponseModel(defaultResponse.code, defaultResponse.message, defaultResponse.data);
  }
}
