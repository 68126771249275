import React from 'react';
import { useTheme } from 'styled-components';

import { PropsWithClassName } from '@Components/helper';

import { Root } from './LogoWithText.styles';

interface Props extends PropsWithClassName {
  height: number;
  isHeader?: boolean;
}

const LogoWithText = (props: Props): React.ReactElement => {
  const { height, isHeader = false } = props;
  const theme = useTheme();

  const textColor = isHeader ? theme.palette.text.logo.header : theme.palette.text.logo.default;

  return (
    <Root className={props.className} data-testid={props.dataTestId ?? 'logo-with-text-root'}>
      <svg height={height} viewBox="0 0 3099 554" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M753.061 411.728C748.954 411.728 745.625 408.398 745.625 404.291V181.463C745.625 174.061 751.625 168.061 759.027 168.061H874.716C878.527 168.061 881.616 171.151 881.616 174.962V174.962C881.616 178.773 878.527 181.863 874.716 181.863H773.899C766.497 181.863 760.497 187.863 760.497 195.264V269.473C760.497 276.875 766.497 282.875 773.899 282.875H863.413C867.224 282.875 870.314 285.964 870.314 289.775V289.775C870.314 293.587 867.224 296.676 863.413 296.676H773.899C766.497 296.676 760.497 302.676 760.497 310.078V404.291C760.497 408.398 757.168 411.728 753.061 411.728V411.728Z"
          fill={textColor}
        />
        <path
          d="M923.962 406.831C922.913 409.768 920.132 411.728 917.014 411.728V411.728C911.901 411.728 908.338 406.654 910.074 401.845L991.525 176.119C993.269 171.284 997.858 168.061 1003 168.061V168.061C1008.14 168.061 1012.73 171.284 1014.47 176.119L1095.92 401.845C1097.66 406.654 1094.1 411.728 1088.98 411.728V411.728C1085.87 411.728 1083.08 409.768 1082.04 406.831L1003.79 187.777C1003.67 187.441 1003.36 187.217 1003 187.217V187.217C1002.64 187.217 1002.32 187.441 1002.2 187.777L923.962 406.831ZM944.462 326.897C944.462 323.085 947.551 319.996 951.362 319.996H1054.63C1058.45 319.996 1061.54 323.085 1061.54 326.897V326.897C1061.54 330.708 1058.45 333.797 1054.63 333.797H951.362C947.551 333.797 944.462 330.708 944.462 326.897V326.897Z"
          fill={textColor}
        />
        <path
          d="M1303.2 228.978C1299.35 228.978 1296.2 226.016 1295.47 222.242C1293.12 210.154 1287.24 200.144 1277.83 192.214C1266.81 182.854 1252.53 178.174 1235 178.174C1223.18 178.174 1212.67 180.356 1203.47 184.718C1194.27 189.081 1187.05 195.03 1181.82 202.565C1176.58 210.1 1173.96 218.746 1173.96 228.502C1173.96 234.768 1175.15 240.439 1177.53 245.516C1179.99 250.513 1183.44 254.994 1187.89 258.96C1192.33 262.847 1197.6 266.258 1203.71 269.192C1209.82 272.127 1216.56 274.705 1223.94 276.926L1250.59 284.778C1259.71 287.555 1268.12 290.767 1275.81 294.416C1283.58 298.064 1290.32 302.427 1296.04 307.503C1301.83 312.5 1306.31 318.37 1309.48 325.112C1312.73 331.854 1314.36 339.627 1314.36 348.431C1314.36 361.281 1311.03 372.782 1304.36 382.935C1297.78 393.088 1288.38 401.099 1276.17 406.968C1264.03 412.759 1249.71 415.654 1233.22 415.654C1217.51 415.654 1203.83 412.997 1192.17 407.682C1180.59 402.368 1171.43 394.991 1164.68 385.553C1159.45 378.17 1155.99 369.928 1154.29 360.827C1153.48 356.48 1157.02 352.715 1161.44 352.715V352.715C1165.42 352.715 1168.67 355.793 1169.47 359.69C1170.95 366.872 1173.84 373.272 1178.13 378.89C1183.84 386.187 1191.41 391.819 1200.85 395.785C1210.29 399.75 1221.08 401.733 1233.22 401.733C1246.3 401.733 1257.84 399.513 1267.84 395.071C1277.83 390.55 1285.65 384.283 1291.28 376.272C1296.91 368.261 1299.72 358.941 1299.72 348.312C1299.72 339.746 1297.7 332.409 1293.66 326.302C1289.61 320.194 1283.62 314.919 1275.69 310.478C1267.84 306.036 1258.08 302.07 1246.42 298.58L1217.99 290.013C1198.95 284.223 1184.4 276.331 1174.32 266.337C1164.33 256.343 1159.33 243.89 1159.33 228.978C1159.33 216.446 1162.66 205.341 1169.32 195.664C1175.99 185.908 1185.03 178.254 1196.45 172.701C1207.95 167.149 1220.92 164.373 1235.36 164.373C1249.87 164.373 1262.68 167.189 1273.79 172.82C1284.89 178.373 1293.7 186.027 1300.2 195.783C1305.32 203.395 1308.64 211.866 1310.17 221.196C1310.86 225.39 1307.45 228.978 1303.2 228.978V228.978Z"
          fill={textColor}
        />
        <path
          d="M1383.96 181.863C1380.14 181.863 1377.05 178.773 1377.05 174.962V174.962C1377.05 171.151 1380.14 168.061 1383.96 168.061H1540.89C1544.7 168.061 1547.79 171.151 1547.79 174.962V174.962C1547.79 178.773 1544.7 181.863 1540.89 181.863H1483.26C1475.86 181.863 1469.86 187.863 1469.86 195.264V404.291C1469.86 408.398 1466.53 411.728 1462.42 411.728V411.728C1458.31 411.728 1454.99 408.398 1454.99 404.291V195.264C1454.99 187.863 1448.99 181.863 1441.58 181.863H1383.96Z"
          fill={textColor}
        />
        <path
          d="M1643.71 411.728C1639.6 411.728 1636.27 408.398 1636.27 404.291V181.463C1636.27 174.061 1642.27 168.061 1649.67 168.061H1765.36C1769.17 168.061 1772.26 171.151 1772.26 174.962V174.962C1772.26 178.773 1769.17 181.863 1765.36 181.863H1664.55C1657.14 181.863 1651.14 187.863 1651.14 195.264V269.473C1651.14 276.875 1657.14 282.875 1664.55 282.875H1754.06C1757.87 282.875 1760.96 285.964 1760.96 289.775V289.775C1760.96 293.587 1757.87 296.676 1754.06 296.676H1664.55C1657.14 296.676 1651.14 302.676 1651.14 310.078V404.291C1651.14 408.398 1647.81 411.728 1643.71 411.728V411.728Z"
          fill={textColor}
        />
        <path
          d="M1860.12 168.061C1864.23 168.061 1867.56 171.391 1867.56 175.497V404.291C1867.56 408.398 1864.23 411.728 1860.12 411.728V411.728C1856.02 411.728 1852.69 408.398 1852.69 404.291V175.497C1852.69 171.391 1856.02 168.061 1860.12 168.061V168.061Z"
          fill={textColor}
        />
        <path
          d="M2137.01 168.061C2141.08 168.061 2144.39 171.364 2144.39 175.438V402.136C2144.39 407.433 2140.09 411.728 2134.79 411.728V411.728C2131.7 411.728 2128.8 410.238 2127 407.726L1974.77 195.463C1974.59 195.216 1974.31 195.069 1974 195.069V195.069C1973.48 195.069 1973.06 195.492 1973.06 196.013V404.292C1973.06 408.398 1969.73 411.728 1965.62 411.728V411.728C1961.51 411.728 1958.19 408.398 1958.19 404.292V177.729C1958.19 172.39 1962.51 168.061 1967.85 168.061V168.061C1970.97 168.061 1973.9 169.565 1975.71 172.099L2127.92 384.564C2128.1 384.811 2128.38 384.958 2128.69 384.958V384.958C2129.21 384.958 2129.63 384.535 2129.63 384.014V175.438C2129.63 171.364 2132.93 168.061 2137.01 168.061V168.061Z"
          fill={textColor}
        />
        <path
          d="M2411.66 244.207C2408.08 244.207 2405.02 241.709 2403.99 238.284C2402.15 232.127 2399.57 226.13 2396.26 220.293C2391.89 212.519 2386.3 205.539 2379.48 199.352C2372.74 193.086 2364.81 188.129 2355.69 184.48C2346.64 180.832 2336.49 179.007 2325.23 179.007C2308.01 179.007 2292.75 183.528 2279.42 192.571C2266.17 201.534 2255.78 214.344 2248.25 231.001C2240.79 247.578 2237.06 267.209 2237.06 289.894C2237.06 312.342 2240.79 331.894 2248.25 348.55C2255.7 365.207 2266.13 378.097 2279.54 387.218C2293.02 396.26 2308.69 400.782 2326.54 400.782C2342.48 400.782 2356.6 397.093 2368.89 389.717C2381.27 382.34 2390.9 371.791 2397.8 358.069C2404.52 344.717 2407.89 328.813 2407.92 310.355C2407.92 309.643 2408.67 309.169 2409.31 309.476V309.476C2410.24 309.919 2409.92 311.31 2408.89 311.31H2337.24C2333.43 311.31 2330.34 308.221 2330.34 304.41V304.41C2330.34 300.599 2333.43 297.509 2337.24 297.509H2409.03C2416.43 297.509 2422.43 303.509 2422.43 310.911V311.31C2422.43 332.806 2418.31 351.327 2410.06 366.873C2401.89 382.34 2390.55 394.238 2376.03 402.566C2361.59 410.895 2345.1 415.059 2326.54 415.059C2305.6 415.059 2287.31 409.864 2271.69 399.473C2256.06 389.082 2243.93 374.527 2235.28 355.808C2226.71 337.01 2222.43 315.038 2222.43 289.894C2222.43 271.017 2224.89 253.884 2229.81 238.496C2234.72 223.108 2241.74 209.941 2250.87 198.996C2259.99 187.97 2270.85 179.523 2283.47 173.653C2296.08 167.704 2310 164.73 2325.23 164.73C2339.03 164.73 2351.44 167.03 2362.47 171.631C2373.49 176.152 2383.01 182.259 2391.02 189.953C2399.11 197.568 2405.66 206.095 2410.65 215.533C2414.13 222.039 2416.81 228.64 2418.72 235.337C2420.01 239.904 2416.41 244.207 2411.66 244.207V244.207Z"
          fill={textColor}
        />
        <path
          d="M2516.32 411.728C2508.92 411.728 2502.92 405.727 2502.92 398.326V181.463C2502.92 174.061 2508.92 168.061 2516.32 168.061H2631.65C2635.46 168.061 2638.55 171.151 2638.55 174.962V174.962C2638.55 178.773 2635.46 181.863 2631.65 181.863H2531.19C2523.79 181.863 2517.79 187.863 2517.79 195.264V269.473C2517.79 276.875 2523.79 282.875 2531.19 282.875H2624.39C2628.2 282.875 2631.29 285.964 2631.29 289.775V289.775C2631.29 293.587 2628.2 296.676 2624.39 296.676H2531.19C2523.79 296.676 2517.79 302.676 2517.79 310.078V384.525C2517.79 391.926 2523.79 397.926 2531.19 397.926H2634.5C2638.32 397.926 2641.41 401.016 2641.41 404.827V404.827C2641.41 408.638 2638.32 411.728 2634.5 411.728H2516.32Z"
          fill={textColor}
        />
        <path
          d="M2727.75 411.728C2723.64 411.728 2720.31 408.398 2720.31 404.292V181.463C2720.31 174.061 2726.31 168.061 2733.71 168.061H2795.39C2810.78 168.061 2824.02 171.075 2835.13 177.104C2846.23 183.132 2854.76 191.5 2860.71 202.208C2866.65 212.837 2869.63 225.21 2869.63 239.329C2869.63 253.289 2866.65 265.583 2860.71 276.212C2854.76 286.761 2846.23 295.011 2835.13 300.959C2824.1 306.908 2810.89 309.883 2795.51 309.883H2735.24C2731.4 309.883 2728.28 306.767 2728.28 302.923V302.923C2728.28 299.079 2731.4 295.962 2735.24 295.962H2795.15C2807.68 295.962 2818.39 293.622 2827.27 288.943C2836.16 284.263 2842.98 277.719 2847.74 269.311C2852.5 260.824 2854.88 250.83 2854.88 239.329C2854.88 227.748 2852.5 217.675 2847.74 209.109C2842.98 200.463 2836.12 193.761 2827.15 189.001C2818.27 184.242 2807.56 181.863 2795.03 181.863H2748.59C2741.18 181.863 2735.18 187.863 2735.18 195.264V404.292C2735.18 408.398 2731.86 411.728 2727.75 411.728V411.728ZM2815.98 301.316C2818.69 301.316 2821.18 302.798 2822.47 305.178L2874.3 400.615C2877.02 405.628 2873.4 411.728 2867.69 411.728V411.728C2864.93 411.728 2862.39 410.211 2861.07 407.779L2809.48 312.212C2806.82 307.291 2810.39 301.316 2815.98 301.316V301.316Z"
          fill={textColor}
        />
        <path
          d="M3087.31 228.978C3083.47 228.978 3080.32 226.016 3079.59 222.241C3077.24 210.154 3071.36 200.144 3061.95 192.214C3050.93 182.854 3036.65 178.174 3019.12 178.174C3007.3 178.174 2996.79 180.356 2987.59 184.718C2978.39 189.081 2971.17 195.03 2965.94 202.565C2960.7 210.1 2958.08 218.746 2958.08 228.502C2958.08 234.768 2959.27 240.439 2961.65 245.516C2964.11 250.513 2967.56 254.994 2972 258.96C2976.45 262.847 2981.72 266.258 2987.83 269.192C2993.94 272.127 3000.68 274.705 3008.06 276.926L3034.71 284.778C3043.83 287.555 3052.24 290.767 3059.93 294.416C3067.7 298.064 3074.44 302.427 3080.16 307.503C3085.95 312.5 3090.43 318.37 3093.6 325.112C3096.85 331.854 3098.48 339.627 3098.48 348.431C3098.48 361.281 3095.15 372.782 3088.48 382.935C3081.9 393.088 3072.5 401.099 3060.29 406.968C3048.15 412.759 3033.83 415.654 3017.34 415.654C3001.63 415.654 2987.95 412.997 2976.29 407.682C2964.71 402.368 2955.55 394.991 2948.8 385.553C2943.57 378.17 2940.11 369.928 2938.41 360.827C2937.6 356.48 2941.14 352.715 2945.56 352.715V352.715C2949.54 352.715 2952.79 355.793 2953.59 359.69C2955.07 366.872 2957.96 373.272 2962.25 378.89C2967.96 386.187 2975.53 391.819 2984.97 395.785C2994.41 399.75 3005.2 401.733 3017.34 401.733C3030.42 401.733 3041.96 399.513 3051.96 395.071C3061.95 390.55 3069.76 384.283 3075.4 376.272C3081.03 368.261 3083.84 358.941 3083.84 348.312C3083.84 339.746 3081.82 332.409 3077.78 326.302C3073.73 320.194 3067.74 314.919 3059.81 310.478C3051.96 306.036 3042.2 302.07 3030.54 298.58L3002.11 290.013C2983.07 284.223 2968.51 276.331 2958.44 266.337C2948.45 256.343 2943.45 243.89 2943.45 228.978C2943.45 216.446 2946.78 205.341 2953.44 195.664C2960.11 185.908 2969.15 178.254 2980.57 172.701C2992.07 167.149 3005.04 164.373 3019.48 164.373C3033.99 164.373 3046.8 167.189 3057.91 172.82C3069.01 178.373 3077.82 186.027 3084.32 195.783C3089.44 203.395 3092.76 211.866 3094.29 221.196C3094.98 225.39 3091.57 228.978 3087.31 228.978V228.978Z"
          fill={textColor}
        />
        <path
          d="M139.069 9.04533H414.238C486.243 9.04533 544.615 67.4172 544.615 139.423V414.591C544.615 486.596 486.243 544.968 414.238 544.968H139.069C67.0637 544.968 8.69182 486.596 8.69182 414.591V139.423C8.69182 67.4172 67.0637 9.04533 139.069 9.04533Z"
          stroke={theme.palette.text.themePrimary}
          strokeWidth="17.3836"
        />
        <line
          x1="112.321"
          y1="171.315"
          x2="112.321"
          y2="395.557"
          stroke={theme.palette.text.themePrimary}
          strokeWidth="20.7197"
          strokeLinecap="round"
        />
        <circle cx="310.895" cy="286.432" r="130.076" stroke={theme.palette.text.themePrimary} strokeWidth="20.5767" />
      </svg>
    </Root>
  );
};

export default LogoWithText;
