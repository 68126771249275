'use client';

import { useCallback, useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import useRewardStore from '@Root/src/store/useRewardStore';
import useTypingStore from '@Root/src/store/useTypingStore';

import { AchievementType } from '@Api/models/UserAchievementModel';

const globalKeyListenerRewards = [AchievementType.Reward_General_Asdf];

export const useGlobalRewards = () => {
  useGlobalKeyDownRewardListener();
  useStayAWhileAndListenRewardListener();
};

// GLOBAL KEY LISTENER REWARDS
export const useGlobalKeyDownRewardListener = () => {
  const isAchieved = useRewardStore((state) => state.isAchieved);

  const isGlobalKeyListenerActive = useCallback(() => {
    return globalKeyListenerRewards.some((achievementType) => !isAchieved(achievementType));
  }, [isAchieved]);

  const { progress, onKeyDown: onKeyDownAsdfReward } = useAsdfRewardListener();

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      onKeyDownAsdfReward(e);
    },
    [onKeyDownAsdfReward]
  );

  useEffect(() => {
    if (!isGlobalKeyListenerActive()) {
      return;
    }

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown, progress, isGlobalKeyListenerActive]);
};

/**
 * Custom hook to handle the "ASDF" reward
 * which tracks the first time a user typed "asdf" four times in a row anywhere on the site
 */
const useAsdfRewardListener = () => {
  const word = 'asdfasdfasdfasdf';

  const [progress, setProgress] = useState('');
  const create = useRewardStore((state) => state.create);

  const onKeyDown = (e: KeyboardEvent) => {
    const newProgress = progress + e.key;

    if (!word.startsWith(newProgress)) {
      setProgress('');
      return;
    }

    if (newProgress !== word) {
      setProgress(newProgress);
      return;
    }

    create(AchievementType.Reward_General_Asdf);
    setProgress(' ');
  };

  return { progress, onKeyDown };
};

// INDEPENDENT REWARDS

/**
 * Custom hook to handle the "Scientist" reward
 * which tracks the first time a user views the advanced stats
 */
export const useScientistRewardListener = () => {
  const create = useRewardStore((state) => state.create);

  const onShowAdvancedStatsClick = () => {
    create(AchievementType.Reward_Result_Scientist);
  };

  return { onShowAdvancedStatsClick };
};

const stayAWhileAndListenTime = 1000 * 60 * 10; // 10 minutes

// todo: improve security. goal time could easy be manipulated, maybe move parts of the logic to the next backend?
/**
 * Custom hook to handle the "Stay a while and listen" reward
 * which tracks the first time a user stays at least 10 minutes on the site
 */
export const useStayAWhileAndListenRewardListener = () => {
  const { create, isAchieved } = useRewardStore(
    useShallow((state) => ({
      create: state.create,
      isAchieved: state.isAchieved,
    }))
  );

  const isActive = !isAchieved(AchievementType.Reward_General_StayAWhileAndListen);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    const interval = setInterval(() => {
      if (performance.now() > stayAWhileAndListenTime) {
        create(AchievementType.Reward_General_StayAWhileAndListen);
        clearInterval(interval);
      }
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [create, isActive]);
};

const afkTime = 1000 * 60 * 60; // 1 hour

// todo: improve security. goal time could easy be manipulated, maybe move parts of the logic to the next backend?
/**
 * Custom hook to handle the "AFK" reward
 * which tracks the first time a user stays at least 1 hour on the typing test without typing
 */
export const useAfkRewardListener = () => {
  const [startTime, setStartTime] = useState(performance.now());

  const { create, isAchieved } = useRewardStore(
    useShallow((state) => ({
      create: state.create,
      isAchieved: state.isAchieved,
    }))
  );

  const isRunning = useTypingStore((state) => state.timing.isRunning);
  const isActive = !isAchieved(AchievementType.Reward_GameMode_Afk);

  useEffect(() => {
    setStartTime(performance.now());
  }, [isRunning]);

  useEffect(() => {
    if (!isActive || isRunning) {
      return;
    }

    const interval = setInterval(
      () => {
        if (performance.now() - startTime > afkTime) {
          create(AchievementType.Reward_GameMode_Afk);
          clearInterval(interval);
          setStartTime(performance.now());
        }
      },
      1000 * 60 // every minute
    );

    return () => {
      clearInterval(interval);
    };
  }, [create, isActive, isRunning, startTime]);
};
