import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { RewardActions, RewardState } from '@Root/src/store/useRewardStore.types';

import { ApiError } from '@Api/ApiError';
import { AchievementType } from '@Api/models/UserAchievementModel';

import { createUserAchievement, UserAchievementCreateRequest } from '@Api-generated';

import { errorToast } from '@Components/helper/toastHelper';

const initialRewardMap = {
  [AchievementType.Reward_General_Asdf]: true,
  [AchievementType.Reward_Result_Scientist]: true,
  [AchievementType.Reward_General_StayAWhileAndListen]: true,
  [AchievementType.Reward_GameMode_Afk]: true,
};

const useRewardStore = create<RewardState & RewardActions>()(
  devtools((set, get): RewardState & RewardActions => ({
    rewardMap: initialRewardMap,
    isAchieved: (achievementType) => {
      return get().rewardMap[achievementType];
    },
    create: async (achievementType: AchievementType) => {
      if (get().isAchieved(achievementType)) {
        return;
      }

      try {
        await createUserAchievement({
          body: {
            achievementType: achievementType as unknown as UserAchievementCreateRequest['achievementType'],
          },
        });

        set((state) => ({
          rewardMap: { ...state.rewardMap, [achievementType]: true },
        }));
      } catch (error) {
        if (error instanceof ApiError) {
          errorToast(error);
        }
      }
    },
    reset: () => {
      set({ rewardMap: initialRewardMap });
    },
  }))
);
export default useRewardStore;
