'use client';

import { LinkProps } from 'next/dist/client/link';
import NextLink from 'next/link';
import React from 'react';
import { useTranslation } from 'react-i18next';

import i18nConfig from '@Root/i18nConfig';

import { isExternalUrl } from '@Components/helper/url';

const Link = (
  props: Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
    LinkProps & {
      children?: React.ReactNode;
    } & React.RefAttributes<HTMLAnchorElement>
) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;

  const getHref = () => {
    const href = typeof props.href === 'string' ? props.href : props.href.href;

    if (href && isExternalUrl(href)) {
      return props.href;
    }

    if (currentLocale === i18nConfig.defaultLocale) {
      return props.href;
    } else {
      return `/${currentLocale}${href}`;
    }
  };

  return <NextLink {...props} href={getHref()} />;
};
export default Link;
